"use client";

import * as React from "react";
import { cn } from "@putt-web/ui/lib/utils";
import { cva, type VariantProps } from "class-variance-authority";

const inputVariants = cva(
  `flex w-full rounded-md border border-input border-neutral-300 bg-neutral-100 caret-primary ring-inset
  file:border-0 file:bg-transparent file:text-md file:font-medium placeholder:text-neutral-500 
  focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-neutral-900
  disabled:cursor-not-allowed disabled:bg-neutral-300 disabled:opacity-50`,
  {
    variants: {
      variant: {
        lg: "h-14 text-lg",
        md: "h-10 text-md",
        sm: "h-8 text-sm",
      },
    },
    defaultVariants: {
      variant: "lg",
    },
  },
);
export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    VariantProps<typeof inputVariants> {
  prefixContent?: React.ReactNode;
  suffixContent?: React.ReactNode;
  showLabel?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      variant = "lg",
      type,
      value,
      placeholder,
      prefixContent,
      suffixContent,
      showLabel = true,
      onChange,
      ...props
    },
    ref,
  ) => {
    const [hasInput, setHasInput] = React.useState<boolean>(!!value);

    const handleExternalChange = (
      event: React.ChangeEvent<HTMLInputElement>,
    ) => {
      setHasInput(event.target.value !== "");
      onChange?.(event);
    };

    React.useEffect(() => {
      setHasInput(!!value);
    }, [value]);

    const shouldShowPlaceholder =
      hasInput && showLabel && placeholder && variant == "lg";

    return (
      <div className="relative">
        {shouldShowPlaceholder && (
          <span
            className={
              "absolute left-3 text-neutral-500 top-2 caption-sm font-normal"
            }
          >
            {placeholder}
          </span>
        )}
        <input
          type={type}
          className={cn(
            inputVariants({ variant, className }),
            shouldShowPlaceholder ? "pt-5 pb-1" : "py-2",
            prefixContent ? "pl-10" : "pl-3",
            suffixContent ? "pr-12" : "pr-3",
          )}
          value={value}
          placeholder={placeholder}
          ref={ref}
          onChange={handleExternalChange}
          {...props}
        />
        {prefixContent && (
          <div
            className={cn(
              "left-3 absolute text-sm text-neutral-500 disabled:opacity-50 transform",
              shouldShowPlaceholder ? "top-7" : "top-1/2 -translate-y-1/2",
            )}
          >
            {prefixContent}
          </div>
        )}
        {suffixContent && (
          <div className="right-3 absolute text-sm text-neutral-500 disabled:opacity-50 transform top-1/2 -translate-y-1/2">
            {suffixContent}
          </div>
        )}
      </div>
    );
  },
);

Input.displayName = "Input";

export { Input, inputVariants };

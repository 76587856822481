import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@putt-web/ui/lib/utils";
import { Loader2 } from "lucide-react";

const buttonVariants = cva(
  "inline-flex items-center justify-center font-bold gap-1 ring-offset-background transition-colors focus-visible:outline-none disabled:pointer-events-none disabled:opacity-50 data-[loading=true]:pointer-events-none data-[loading=true]:opacity-50",
  {
    variants: {
      variant: {
        default: "bg-primary text-neutral-100 hover:bg-primary-700",
        secondary:
          "bg-neutral-100 text-neutral-900 border-solid border border-neutral-300",
        inverse: "bg-neutral-900 text-neutral-100",
        ghost:
          "bg-neutral-100 text-primary border-solid border border-primary hover:border-primary-700 hover:text-primary-700",
        link: "text-primary underline underline-offset-4 hover:text-primary-700",
        special: "bg-secondary text-neutral-100 hover:bg-secondary-700",
        text: "text-neutral-900 hover:text-primary-700",
        "active-tab": "bg-neutral-300 text-neutral-900",
        "calendar-day": "text-sm hover:bg-primary hover:text-neutral-100",
        "calendar-nav-button":
          "border border-neutral-400 hover:border-primary hover:bg-primary hover:text-neutral-100",
        "date-picker":
          "border border-solid border-neutral-300 min-w-36 font-semibold",
      },
      size: {
        default: "h-10 rounded-md px-4 py-2 text-md",
        sm: "h-9 rounded-md px-3 text-sm",
        lg: "h-11 rounded-md px-8 text-lg",
        icon: "h-10 w-10 rounded-full",
        none: "h-auto px-0 py-0 text-md",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  loading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { className, children, loading, variant, size, asChild = false, ...props },
    ref,
  ) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
        data-loading={loading}
      >
        {children}
        {loading && <Loader2 className="ml-2 w-6 h-6 animate-spin" />}
      </Comp>
    );
  },
);
Button.displayName = "Button";

export { Button, buttonVariants };
